import { useEffect, useState } from 'react'
import { Heading, Flex, Text, Skeleton, ChartIcon, CommunityIcon, SwapIcon, SwapHome, Box } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { formatBigInt, formatLocalisedCompactNumber, formatNumber } from '@pancakeswap/utils/formatBalance'
import useSWRImmutable from 'swr/immutable'
import { ChainId } from '@pancakeswap/sdk'
import { publicClient } from 'utils/wagmi'
import IconCard, { IconCardData } from '../IconCard'
import StatCardContent from './StatCardContent'
import GradientLogo from '../GradientLogoSvg'

const Stats = () => {
  const { t } = useTranslation()
  const { theme, isDark } = useTheme()

  const { data: tvl } = useSWRImmutable('tvl')
  const { data: txCount } = useSWRImmutable('totalTx30Days')
  const { data: addressCount } = useSWRImmutable('addressCount30Days')
  const trades = formatLocalisedCompactNumber(txCount)
  const users = formatLocalisedCompactNumber(addressCount)
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const tvlText = t(
    'ALLTRA Swap has fostered a remarkable connection with a vast network of users, who collectively manage over $%tvl% in funds. This incredible level of connectivity showcases the trust and confidence that our community places in our platform. We are honored to be a part of this interconnected ecosystem, where participants actively engage and manage substantial amounts of capital, reinforcing our commitment to facilitating a robust decentralized environment.',
    { tvl: tvlString },
  )

  const UsersCardData: IconCardData = {
    icon: <CommunityIcon color="#0351EA" width="30px" />,
    background: isDark ? '#171717' : '#ffffff',
  }

  const TradesCardData: IconCardData = {
    icon: <SwapHome color="#1BC5DC" width="28px" />,
    background: isDark ? '#171717' : '#ffffff',
    borderColor: '#373737',
  }

  const StakedCardData: IconCardData = {
    icon: <ChartIcon color="#DC781B" width="26px" />,
    background: isDark ? '#171717' : '#ffffff',
    borderColor: '#373737',
  }

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      <GradientLogo height="140px" width="140px" mb="40px" />
      <Heading textAlign="center" scale="xl" color={isDark ? 'white' : '#25356C'} mb={30}>
        {t('Balance of Payments Balance of trade')}
      </Heading>
      <Text textAlign="center" color={isDark ? 'white' : '#25356C'} mb={'20px'} maxWidth={645}>
        {tvlText}
      </Text>
      {/* <Flex flexWrap="wrap">
        <Text display="inline" textAlign="center" color={isDark ? "white" : "#25356C"} mb="20px">
          {entrusting}
          <>{tvl ? <>{tvlString}</> : <Skeleton display="inline-block" height={16} width={70} mt="2px" />}</>
          {inFunds}
        </Text>
      </Flex> */}

      <Text textAlign="center" color={isDark ? '#1BCA0D' : '#263A4C'} bold mb="67px">
        {t('Will you join them?')}
      </Text>

      <Flex maxWidth="100%" flexDirection={['column', null, null, 'row']} flexWrap="wrap" style={{ gap: '16px' }}>
        <Box style={{ border: '1px solid #373737' }} borderRadius="24px">
          <IconCard {...UsersCardData}>
            <StatCardContent
              headingText={t('%users% users', { users })}
              bodyText={t('in the last 30 days')}
              highlightColor={'#AD1CFC'}
            />
          </IconCard>
        </Box>
        <Box style={{ border: '1px solid #373737' }} borderRadius="24px">
          <IconCard {...TradesCardData}>
            <StatCardContent
              headingText={t('%trades% trades', { trades })}
              bodyText={t('made in the last 30 days')}
              highlightColor={'#1BCA0D'}
            />
          </IconCard>
        </Box>
        <Box style={{ border: '1px solid #373737' }} borderRadius="24px">
          <IconCard {...StakedCardData}>
            <StatCardContent
              headingText={t('%tvl% staked', { tvl: tvlString })}
              bodyText={t('Total Value Locked')}
              highlightColor={'#DC781B'}
            />
          </IconCard>
        </Box>
      </Flex>
    </Flex>
  )
}

const StakeAbi = [
  {
    inputs: [],
    name: 'totalStakedToken',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
] as const

export default Stats
