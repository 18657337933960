import useTheme from 'hooks/useTheme'
import { TranslateFunction } from '@pancakeswap/localization'
import { SalesSectionProps } from '.'

export const swapSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Trade anything. No registration, no hassle.'),
  // headingSubText: t('Experience Hyper-Dex Exchange’s Phenomenal Growth!'),
  colorHeadingText: useTheme().isDark ? 'white' : '#122549',
  firstColorText: useTheme().isDark ? '#1BCA0D' : '#122549',
  colorHeadingSubText: '#1FC7D4',
  bodyText: t('Trade any token on ALLTRA Smart Chain in seconds, just by connecting your wallet.'),
  reverse: false,
  primaryButton: {
    to: '/swap',
    text: t('Trade Now'),
    external: false,
  },
  // secondaryButton: {
  //   to: 'https://docs.pancakeswap.finance/',
  //   text: t('Learn'),
  //   external: true,
  // },
  images: {
    path: '/images/home/home4/',
    attributes: [{ src: 'image1', alt: 'image' }],
  },
})

export const earnSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('Earn passive income with crypto.'),
  colorHeadingText: useTheme().isDark ? 'white' : '#122549',
  firstColorText: '#1BCA0D',
  bodyText: t('ALLTRA Swap makes it easy to make your crypto work for you.'),
  reverse: true,
  primaryButton: {
    to: '/farms',
    text: t('Explore'),
    external: false,
  },
  // secondaryButton: {
  //   to: 'https://docs.pancakeswap.finance/products/yield-farming',
  //   text: t('Learn'),
  //   external: true,
  // },
  images: {
    path: '/images/home/home5/',
    attributes: [{ src: 'image1', alt: t('image') }],
  },
})

export const cakeSectionData = (t: TranslateFunction): SalesSectionProps => ({
  headingText: t('ALLTRA makes our world go round.'),
  colorHeadingText: useTheme().isDark ? "white" : '#122549',
  firstColorText: '#1BCA0D',
  bodyText: t(
    'ALL token is at the heart of the ALLTRA Swap ecosystem. Buy it, win it, farm it, spend it, stake it... heck, you can even vote with it!',
  ),
  reverse: false,
  primaryButton: {
    to: '/swap?outputCurrency=0x0d9793861AEB9244AD1B34375a83A6730F6AdD38&chainId=651940',
    text: t('Buy HYDX'),
    external: false,
  },
  // secondaryButton: {
  //   to: 'https://docs.pancakeswap.finance/tokenomics/cake',
  //   text: t('Learn'),
  //   external: true,
  // },

  images: {
    path: '/images/home/cake/',
    attributes: [
      { src: 'image1', alt: t('image') },
    ],
  },
})
